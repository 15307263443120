<template lang="pug">
  .container(style="margin-top:150px")
    h1 Aviso de Privacidad
    p(style="text-align:justify") El presente aviso de privacidad establece los términos en los que el sitio http://oics.ccilaguna.org.mx/ usa y protege la información que es proporcionada por sus usuarios al momento de utilizar su sitio web y redes sociales, conforme a lo dispuesto por la Ley General de Protección de Datos Personales en Posesión de Sujetos Obligados, y demás normatividad que resulte aplicable.
    p(style="text-align:justify") Este sitio está comprometido con la protección de sus datos personales, mediante su tratamiento legítimo, controlado e informado, a efecto de garantizar la privacidad, así como su derecho a la autodeterminación informativa.
    p(style="text-align:justify") Nuestro sitio web emplea la información, con el objetivo de proporcionar el mejor servicio posible, particularmente para mantener un registro de usuarios y poder compartir información. Es posible que sean enviados correos electrónicos de manera periódica a través de nuestro sitio web u otras tecnologías sobre información que consideremos relevante o pueda brindarle algún beneficio.
    p(style="text-align:justify") De conformidad con lo señalado, se entiende que está de acuerdo con el presente aviso de privacidad. Por lo anterior, y para prevenir el acceso no autorizado a sus datos, con el fin de asegurar que la información sea utilizada para los fines establecidos, se hace de su conocimiento que para poder ejercer sus derechos ARCO (Acceso, Rectificación, Cancelación u Oposición) al tratamiento de sus datos, deberá presentar una solicitud conforme a lo establecido en el artículo 29 de la Ley citada, incluyendo nombre, número telefónico, dirección y correo electrónico.
    p(style="text-align:justify") Su solicitud deberá ser enviada al correo: contacto@ccilaguna.org.mx
    p(style="text-align:justify") El sitio http://oics.ccilaguna.org.mx/ se reserva el derecho de cambiar, modificar, complementar y/o alterar los términos del presente aviso de privacidad en cualquier momento.
</template>

<script>
export default {
  name: "Privacy"
}
</script>

<style scoped>

</style>
